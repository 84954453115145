import { Feature } from 'ol';
import { createEmpty, extend, getCenter } from 'ol/extent';
import Point from 'ol/geom/Point';
import { fromExtent } from 'ol/geom/Polygon';
import { Select, Translate } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import { Cluster } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Icon, Style } from 'ol/style';
import { del, ref, set } from 'vue-demi';
import { useEventHook } from '../eventHook';
import { createCache } from './cache';
import {
  genSvgGrad,
  transformCoordinates,
  transformCoordinatesToLonLat
} from './utils';
import { ColorNames, colors } from './utils/data';
import { throttle } from 'throttle-debounce';

export function useMarkers(map) {
  const markerIds = ref([]);
  const selectedMarkerIds = ref({});
  const editMarkerState = ref({});
  const mapCache = createCache();
  const markerHoverEvent = useEventHook();
  const markerClickEvent = useEventHook();
  const markerAltClickEvent = useEventHook();
  const editMode = ref(false);

  const getMarkerAlarmStyle = selected => {
    const key = `alarmed:background:${selected}`;
    if (mapCache.has(key)) {
      return mapCache.get(key);
    }
    const style = new Style({
      image: selected
        ? new Icon({
            src: require('@/assets/pin-alarmed.png'),
            color: colors[ColorNames.red],
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            scale: 0.5
          })
        : new Icon({
            src: require('@/assets/circle-alarmed.png'),
            color: colors[ColorNames.red],
            scale: 0.5
          })
    });
    mapCache.set(key, style);
    return style;
  };

  const getMarkerBackgroundStyle = (colorName, selected, alarmed) => {
    const key = `color:${colorName}:selected:${selected}:alarmed:${alarmed}`;
    if (mapCache.has(key)) {
      return mapCache.get(key);
    }
    const resultStyle = [];
    if (alarmed) {
      resultStyle.push(getMarkerAlarmStyle(selected));
    }
    if (selected) {
      if (alarmed) {
        resultStyle.push(
          new Style({
            image: new Icon({
              src: require('@/assets/circle.png'),
              color: colors[colorName] || colors[ColorNames.default],
              scale: 0.5,
              anchor: [0.5, 130],
              anchorXUnits: 'fraction',
              anchorYUnits: 'pixels'
            })
          })
        );
      } else {
        resultStyle.push(
          new Style({
            image: new Icon({
              src: require('@/assets/pin.png'),
              color: colors[colorName] || colors[ColorNames.default],
              anchor: [0.5, 1],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              scale: 0.5
            })
          })
        );
      }
    } else {
      resultStyle.push(
        new Style({
          image: new Icon({
            src: require('@/assets/circle.png'),
            color: colors[colorName] || colors[ColorNames.default],
            scale: 0.5
          })
        })
      );
    }
    mapCache.set(key, resultStyle);
    return resultStyle;
  };

  const getPointStyle = () => {
    return new Style({
      image: new Circle({
        radius: 3,
        fill: new Fill({
          color: '#f00'
        })
      }),
      geometry: function(feature) {
        const coordinates = feature.getGeometry().getCoordinates();
        return new Point(coordinates);
      }
    });
  };

  const getMarkerIconStyle = (icon = 'car', selected, alarmed) => {
    const key = `icon:${icon}:selected:${selected}:alarmed:${alarmed}`;
    if (mapCache.has(key)) {
      return mapCache.get(key);
    }
    let style;
    if (!selected) {
      const image = new Icon({
        src: icon
      });
      let origImage = image.getImage();
      if (!origImage.width) {
        origImage.addEventListener(
          'load',
          function() {
            image.setScale([24 / origImage.width, 24 / origImage.height]);
          },
          { once: true }
        );
      } else {
        image.setScale([24 / origImage.width, 24 / origImage.height]);
      }
      style = new Style({
        image
      });
    } else {
      if (alarmed) {
        const image = new Icon({
          src: icon,
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          anchor: [0.5, 59]
        });
        let origImage = image.getImage();
        if (!origImage.width) {
          // TODO needs to be refactor
          origImage.addEventListener(
            'load',
            function() {
              image.setScale([24 / origImage.width, 24 / origImage.height]);
              image.setAnchor([0.5, 59 / (24 / origImage.height)]);
            },
            { once: true }
          );
        } else {
          image.setScale([24 / origImage.width, 24 / origImage.height]);
          image.setAnchor([0.5, 59 / (24 / origImage.height)]);
        }
        style = new Style({
          image
        });
      } else {
        const image = new Icon({
          src: icon,
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          anchor: [0.5, 46]
        });
        let origImage = image.getImage();
        if (!origImage.width) {
          origImage.addEventListener(
            'load',
            function() {
              image.setScale([24 / origImage.width, 24 / origImage.height]);
              image.setAnchor([0.5, 46 / (24 / origImage.height)]);
            },
            { once: true }
          );
        } else {
          image.setScale([24 / origImage.width, 24 / origImage.height]);
          image.setAnchor([0.5, 46 / (24 / origImage.height)]);
        }
        style = new Style({
          image
        });
      }
    }
    mapCache.set(key, style);
    return style;
  };
  const source = new VectorSource({
    features: []
  });

  const clusterSource = new Cluster({
    distance: 32,
    source: source
  });

  const markerLayer = new VectorLayer({
    source: clusterSource,
    style: function(feature) {
      const features = feature.get('features');
      if (features.length > 1) {
        const featureColors = [
          ...new Set(
            features.map(i => colors[i.get('color')] || colors['default'])
          )
        ].sort();
        const selectedFeatures = features.filter(i => i.get('selected'));
        const alarmed = features.some(i => i.get('alarmed'));
        const key = `colors:${JSON.stringify(featureColors)}:selectedFeatures:${
          selectedFeatures.length
        }:features:${features.length}:alarmed:${alarmed}`;
        if (mapCache.has(key)) {
          return mapCache.get(key);
        }
        const grads = genSvgGrad(
          featureColors,
          selectedFeatures.length
            ? `${selectedFeatures.length}/${features.length}`
            : features.length,
          alarmed
        );
        const style = new Style({
          image: new Icon({
            src: grads
          })
        });
        mapCache.set(key, style);
        return style;
      } else if (features.length === 1) {
        const selected = features[0].get('selected');
        const alarmed = features[0].get('alarmed');
        const color = features[0].get('color');
        const icon = features[0].get('icon');
        const edit = features[0].get('edit');
        const key = `selected:${selected}:color:${color}:icon:${icon}:alarmed:${alarmed}:edit:${edit}`;
        if (mapCache.has(key)) {
          return mapCache.get(key);
        }
        const style = [
          ...getMarkerBackgroundStyle(color, selected, alarmed),
          getMarkerIconStyle(icon, selected, alarmed)
        ];
        if (edit) {
          style.push(getPointStyle(features[0]));
        }
        mapCache.set(key, style);
        return style;
      }
      return null;
    }
  });

  const getDefaultCenter = () => {
    const extent = map.getView().calculateExtent(map.getSize());
    const geom = fromExtent(extent);
    geom.scale(0.8);
    return transformCoordinatesToLonLat(getCenter(geom.getExtent()));
  };

  const selectFeature = (id, selected) => {
    const feature = source.getFeatureById(id);
    if (feature) {
      feature.set('selected', selected);
    }
    selected
      ? set(selectedMarkerIds.value, id, true)
      : del(selectedMarkerIds.value, id);
  };

  const unselectAllMarkers = () => {
    Object.keys(selectedMarkerIds.value).forEach(id => {
      const feature = source.getFeatureById(id);
      if (feature) {
        feature.set('selected', false);
      }
    });
    selectedMarkerIds.value = {};
  };

  const selectMarker = id => {
    selectFeature(id, true);
  };

  const selectMarkers = (ids = []) => {
    unselectAllMarkers();
    ids.forEach(id => {
      selectMarker(id);
    });
  };

  const unselectMarker = id => {
    selectFeature(id, false);
  };

  const zoomInFeature = f => {
    const clusterFeatures = clusterSource.getFeatures();
    for (let i = 0; i < clusterFeatures.length; i++) {
      const index = clusterFeatures[i]
        .get('features')
        .findIndex(feature => feature.getId() === f.getId());
      if (index > -1) {
        if (clusterFeatures[i].get('features').length === 1) {
          map.getView().animate({
            center: f.getGeometry().getCoordinates(),
            duration: 700
          });
        } else {
          const extent = createEmpty();
          const features = clusterFeatures[i].get('features');
          let minDistance = Number.MAX_SAFE_INTEGER;
          const [fx, fy] = f.getGeometry().getCoordinates();
          features.forEach(feature => {
            if (feature === f) return;
            const geometry = feature.getGeometry();
            const [x, y] = geometry.getCoordinates();
            minDistance = Math.min(
              minDistance,
              Math.sqrt((fx - x) * (fx - x) + (fy - y) * (fy - y))
            );
            extend(extent, geometry.getExtent());
          });
          const zoom = map.getView().getZoomForResolution(minDistance / 100);

          map.getView().animate({
            zoom,
            center: [fx, fy]
          });
        }
        break;
      }
    }
  };

  const zoomInAndSelectMarker = id => {
    selectFeature(id, true);
    const feature = source.getFeatureById(id);
    if (feature) {
      zoomInFeature(feature);
    }
  };

  const zoomOut = () => {
    const extent = createEmpty();
    source.getFeatures().forEach(feature => {
      extend(extent, feature.getGeometry().getExtent());
    });
    map.getView().fit(extent, {
      padding: [100, 100, 100, 100],
      duration: 1000
    });
  };

  const setMarkers = (objects = []) => {
    markerIds.value = objects.map(object => object.id || object);
  };

  const createFeature = ({ name, id, color, iconSrc, lat, lon, alarmed }) => {
    const feature = new Feature({
      name: name,
      color: color,
      icon: iconSrc,
      alarmed,
      selected: !!selectedMarkerIds.value[id],
      edit: false,
      geometry: new Point(transformCoordinates([lon, lat]))
    });
    feature.setId(id.toString());

    return feature;
  };

  const addMarker = ({ name, id, color, iconSrc, lat, lon, alarmed }) => {
    const feature = createFeature({
      name,
      id,
      color,
      iconSrc,
      lat,
      lon,
      alarmed
    });
    source.addFeature(feature);
  };

  const addMarkers = markers => {
    const features = markers.map(
      ({ name, id, color, iconSrc, lat, lon, alarmed }) => {
        return createFeature({ name, id, color, iconSrc, lat, lon, alarmed });
      }
    );

    source.addFeatures(features);
  };

  const clearMarkers = () => {
    source.clear();
  };

  const updateMarker = ({ name, id, color, iconSrc, lat, lon, alarmed }) => {
    const existingFeature = source.getFeatureById(id);
    if (!existingFeature) {
      addMarker({ name, id, color, iconSrc, lat, lon, alarmed });
    } else {
      if (!editMarkerState.value[id]) {
        existingFeature.setGeometry(
          new Point(transformCoordinates([lon, lat]))
        );
        existingFeature.setProperties({ name, color, icon: iconSrc, alarmed });
      }
    }
  };

  const removeMarker = markerId => {
    const feature = source.getFeatureById(markerId);
    if (feature) {
      source.removeFeature(feature);
    }
  };

  const editFeaturesSource = new VectorSource();
  const l = new VectorLayer({
    source: editFeaturesSource
  });

  map.addLayer(l);

  const updateEditMarkerState = (objectId, { lat, lon }) => {
    editMarkerState.value = {
      ...editMarkerState.value,
      [objectId]: {
        lat,
        lon
      }
    };
  };

  const editSelect = new Select({
    layers: [l],
    hitTolerance: 48
  });

  editSelect.on('select', e => {
    e.selected.forEach(feature => {
      feature.set('edit', true);
    });
    e.deselected.forEach(feature => {
      feature.set('edit', false);
    });
  });

  map.addInteraction(editSelect);
  const translateInteraction = new Translate({
    features: editSelect.getFeatures(),
    hitTolerance: 48
  });

  translateInteraction.on(['translating', 'translateend'], e => {
    const features = e.features.getArray();
    features.forEach(feature => {
      const objectId = feature.getId();
      const [lon, lat] = transformCoordinatesToLonLat(
        feature.getGeometry().getCoordinates()
      );
      updateEditMarkerState(objectId, {
        lat,
        lon
      });
    });
  });

  map.addInteraction(translateInteraction);

  const enableEditMarker = objectId => {
    editMode.value = true;
    translateInteraction.setActive(true);
    editFeaturesSource.clear();
    const setEditForFeature = id => {
      const feature = source.getFeatureById(id);
      if (feature) {
        editFeaturesSource.addFeature(feature);
        feature.set('edit', true);
        editSelect.getFeatures().push(feature);
        // highlightFeature(feature);
      }
    };

    if (Array.isArray(objectId)) {
      objectId.forEach(id => {
        setEditForFeature(id);
      });
    } else {
      setEditForFeature(objectId);
    }
  };

  const disableEditMarker = objectId => {
    editMode.value = false;
    editFeaturesSource.clear();
    translateInteraction.setActive(false);
    const setEditForFeature = id => {
      const feature = source.getFeatureById(id);
      if (feature) {
        feature.set('edit', false);
        editSelect.getFeatures().remove(feature);
      }
    };

    if (Array.isArray(objectId)) {
      objectId.forEach(id => {
        setEditForFeature(id);
      });
    } else {
      setEditForFeature(objectId);
    }
  };

  const selectSingleClick = new Select({
    style: false,
    layers: [markerLayer]
  });

  selectSingleClick.on('select', evt => {
    if (evt.selected.length > 0) {
      const features = evt.selected[0].get('features');
      // prevent if feature in edit mode
      if (editMode.value) {
        return;
      }
      if (evt.mapBrowserEvent.originalEvent.altKey) {
        const featureIds = features.map(feature => feature.getId());
        markerAltClickEvent.trigger(featureIds);
        return;
      }
      if (features.length === 1) {
        unselectAllMarkers();
        selectFeature(features[0].getId(), true);
        markerClickEvent.trigger(features[0].getId());
      } else {
        const extent = createEmpty();
        features.forEach(feature => {
          extend(extent, feature.getGeometry().getExtent());
        });
        map.getView().fit(extent, {
          padding: [400, 400, 400, 400],
          duration: 700
        });
      }
    }
  });

  map.addInteraction(selectSingleClick);

  const throttlePointerMove = throttle(200, function(evt) {
    const feature = this.forEachFeatureAtPixel(evt.pixel, (feature, layer) =>
      layer === markerLayer ? feature : false
    );
    const features = feature?.get('features');
    const featureId = features?.[0]?.getId();
    if (featureId) {
      this.getTargetElement().style.cursor = 'pointer';
      const { x, y } = map.getTargetElement().getBoundingClientRect();
      const coord = map.getPixelFromCoordinate(
        feature
          ?.get('features')?.[0]
          ?.getGeometry()
          ?.getCoordinates()
      );
      if (features.length === 1) {
        markerHoverEvent.trigger({
          hover: true,
          id: featureId,
          coordinates: [coord[0] + x, coord[1] + y]
        });
      }
    } else {
      this.getTargetElement().style.cursor = '';
      markerHoverEvent.trigger({
        hover: false
      });
    }
  });

  map.on('pointermove', throttlePointerMove);

  return {
    markerLayer,
    addMarker,
    addMarkers,
    updateMarker,
    removeMarker,
    setMarkers,
    clearMarkers,
    selectMarker,
    selectMarkers,
    unselectMarker,
    zoomInAndSelectMarker,
    zoomOut,
    markerIds,
    selectedMarkerIds,
    unselectAllMarkers,
    onClickMarker: markerClickEvent.on,
    onHoverMarker: markerHoverEvent.on,
    onAltClickMarker: markerAltClickEvent.on,
    enableEditMarker,
    disableEditMarker,
    editMarkerState,
    getDefaultCenter
  };
}
